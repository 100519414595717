:root {
  --veratad-primary-bg: #172DDC;
  --veratad-primary-color: #FFFFFF;
  --veratad-secondary-bg: #FFF;
  --veratad-secondary-color: #172DDC;
  --veratad-icon: #172DDC;
  
}

blinkid-in-browser {
  max-width: 400px;
  

  /* Customization of UI - see variables in src/components/shared/styles/_globals.scss */
  /*
  --mb-component-background: red;
  --mb-component-action-label: none;
  --mb-component-border-width: 0;
  --mb-component-action-buttons-width: 100%;
  --mb-component-action-buttons-justify-content: space-evenly;
  
  --mb-component-button-border-radius: 24px;
  */
}

.content{
  min-height:600px;
  max-height:600px;
  height:600px;
}

.veratad-primary{
  border-radius: 10px;
  font-size:15px;
  padding:10px 20px 10px 20px;
  background-color: var(--veratad-primary-bg);
  color: var(--veratad-primary-color);
  width:100%;
  cursor:pointer;
  border:1px solid var(--veratad-primary-bg);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.veratad-primary:hover{
  background-color:#ffffff;
  color: var(--veratad-primary-bg);
  border:1px solid var(--veratad-primary-bg);
}

.veratad-primary:focus {
  outline: none;
  box-shadow: none;
}

.alert-veratad{
  background-color:rgba(23, 45, 220, .1);
  color:#172DDC;
}

.no-style-button{
  border:none;
  background-color:transparent;
}

.no-style-button:focus {
  outline: none;
  box-shadow: none;
}

.veratad-secondary{
  border-radius: 10px;
  font-size:15px;
  padding:10px 20px 10px 20px;
  background-color: var(--veratad-secondary-bg);
  color: var(--veratad-secondary-color);
  border:1px solid var(--veratad-secondary-color);
  width:100%;
  cursor:pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.veratad-secondary:hover{
  color: var(--veratad-secondary-bg);
  background-color: var(--veratad-secondary-color);
  border:1px solid var(--veratad-secondary-bg);
}

.veratad-secondary:focus {
  outline: none;
  box-shadow: none;
}

.close{
  font-size:10px;
  cursor:pointer;
}

.result-icon{
  font-size:60px;
  color: var(--veratad-icon);
}

.alert-icon{
  font-size:50px;
}

.error-icon{
  font-size:80px;
  color: #000;
}

.dropZone{
  border: 2px dashed #d3d3d3;
  padding:15px;
  background-color:rgba(211, 211, 211, .1);
}

.dropZone-large{
  border: 2px dashed #d3d3d3;
  padding:15px;
  height:225px;
  background-color:rgba(211, 211, 211, .1);
}

.back-navigation {
  position: absolute;
  top: 5px !important;
  left:15px !important;
}

.navigation-button{
  border:none;
  background-color:transparent;
}

.navigation-button:focus {
  outline: none;
  box-shadow: none;
}

.close-navigation {
  position: absolute;
  top: 5px !important;
  right:20px !important;
  cursor:pointer;
}

.navigation-item {
  font-size:15px;
}

a:link {
  text-decoration:none;
}

/* visited link */
a:visited {
  text-decoration:none;
}

/* mouse over link */
a:hover {
  text-decoration:none;
}

/* selected link */
a:active {
  text-decoration:none;
}
