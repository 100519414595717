.method-select{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding-top:20px;

}

.method-select:hover {
  background-color: rgb(220,220,220, .2);
}

.easiestOption{
  padding:3px 5px 3px 5px;
  background-color:rgba(23, 45, 220, 0.1);
  color: #172DDC;
}

.icon{
  color:#172DDC;
}

.circle-icon {

  font-size: 40px;
  color: #172DDC;

}

.circle-icon-middle {
  display: inline-block;
  border-radius: 100px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.8em 0.9em;
  font-size: 30px;
}
