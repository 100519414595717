@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html,
body {
  font-family: 'Poppins', sans-serif;
}

.circle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #000; /* or any other color you prefer */
  }
  
  .fas {
    font-size: 14px;
  }
  
  .table-icon-text {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .table-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  
  
  
  
  
  
  
  
  
  
  
  